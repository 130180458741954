<template>
  <div>
    <div class="flex h-full flex-col space-y-2 border-b border-gray-800 px-6 py-4">
      <div class="flex items-center space-x-2">
        <span class="block text-sm font-semibold text-gray-200">Choose a SEC Filing form type to set alerts on </span>
        <span v-if="required.alertType" class="block text-xs text-red-400">Required</span>
      </div>
      <BaseSelect
        dropdown-width-class="w-full"
        button-width-class="w-full"
        :display-count="6"
        :placeholder="'Select Form Type'"
        :multiple="true"
        :searchable="true"
        :options="secDropdownOptions"
        v-model="alertData.keywords"
        id="sec-fillings-dropdown"
      />
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import useEmitter from '@/composeables/emitter';
import { secFormTypes } from '../../../../data/secAutofill';

const emitter = useEmitter();
const props = defineProps({
  alert: { type: Object, default: () => null }
});

const secDropdownOptions = ref([]);
const required = ref({ alertType: false });
const alertData = ref(props.alert ? props.alert.config : { keywords: [] });

function showValidationError(data) {
  required.value.alertType = data.alertType;
  setTimeout(function () {
    required.value.alertType = false;
  }, 5000);
}
function editData() {
  if (props.alert) {
    alertData.value = props.alert.config;
  }
}

watch(
  alertData,
  () => {
    emitter.$emit('config:alert', { config: alertData.value });
  },
  { deep: true, immediate: true }
);
watch(
  () => alertData.value.keywords,
  () => {
    if (alertData.value.keywords?.includes(0)) {
      alertData.value.keywords = [];
      secDropdownOptions.value.find(e => e.id == 0).disabled = true;
    } else if (alertData.value.keywords && secDropdownOptions.value.length) {
      secDropdownOptions.value.find(e => e.id == 0).disabled = false;
    }
  },
  { immediate: true }
);
onMounted(() => {
  emitter.$on('SECForm', data => {
    showValidationError(data);
  });
  editData();
  const baseOptions = [{ label: 'Deselect All', id: 0, disabled: true }];
  secDropdownOptions.value = baseOptions.concat(
    secFormTypes.map(r => {
      return { id: r, label: r, disabled: false };
    })
  );
});
onBeforeUnmount(() => {
  emitter.$off('SECForm');
});
</script>
