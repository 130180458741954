export const secFormTypes = [
  '1',
  '3',
  '4',
  '5',
  '25',
  '26',
  '144',
  '425',
  '487',
  '497',
  '1-A',
  '1-A POS',
  '1-A-W',
  '1-A-W/A',
  '1-A/A',
  '1-K',
  '1-K/A',
  '1-SA',
  '1-SA/A',
  '1-U',
  '1-U/A',
  '1-Z',
  '1-Z/A',
  '1/A',
  '10-12B',
  '10-12G',
  '10-12G/A',
  '10-D',
  '10-D/A',
  '10-K',
  '10-K/A',
  '10-KT',
  '10-Q',
  '10-Q/A',
  '10-QT',
  '11-K',
  '13F-HR',
  '13F-HR/A',
  '13F-NT',
  '13F-NT/A',
  '144/A',
  '15-12B',
  '15-12B/A',
  '15-12G',
  '15-12G/A',
  '15-15D',
  '15-15D/A',
  '15F-12B',
  '15F-15D',
  '18-K',
  '18-K/A',
  '19B-4E',
  '20-F',
  '20-F/A',
  '20FR12B',
  '20FR12B/A',
  '20FR12G',
  '24F-2NT',
  '24F-2NT/A',
  '25-NSE',
  '25-NSE/A',
  '253G1',
  '253G2',
  '253G3',
  '253G4',
  '3/A',
  '305B2',
  '4/A',
  '40-17F1',
  '40-17F2',
  '40-17G',
  '40-17G/A',
  '40-17GCS',
  '40-24B2',
  '40-33',
  '40-6B',
  '40-8F-2',
  '40-8F-2/A',
  '40-APP',
  '40-APP/A',
  '40-F',
  '40-F/A',
  '40-OIP',
  '40-OIP/A',
  '40FR12B',
  '40FR12B/A',
  '40FR12G',
  '40FR12G/A',
  '424A',
  '424B1',
  '424B2',
  '424B3',
  '424B4',
  '424B5',
  '424B7',
  '424B8',
  '424H',
  '424H/A',
  '485APOS',
  '485BPOS',
  '485BXT',
  '486APOS',
  '486BPOS',
  '486BXT',
  '497AD',
  '497J',
  '497K',
  '5/A',
  '6-K',
  '6-K/A',
  '6B ORDR',
  '8-A12B',
  '8-A12B/A',
  '8-A12G',
  '8-A12G/A',
  '8-K',
  '8-K/A',
  '8-K12B',
  '8-K12B/A',
  '8-K12G3',
  '8F-2 NTC',
  '8F-2 ORDR',
  'ABS-15G',
  'ABS-15G/A',
  'ABS-EE',
  'ABS-EE/A',
  'ADV-NR',
  'APP NTC',
  'APP ORDR',
  'APP WD',
  'APP WDG',
  'ARS',
  'ATS-N-W',
  'ATS-N/CA',
  'ATS-N/MA',
  'ATS-N/UA',
  'AW',
  'C',
  'C-AR',
  'C-TR',
  'C-U',
  'C-U-W',
  'C-W',
  'C/A',
  'C/A-W',
  'CB',
  'CB/A',
  'CERT',
  'CFPORTAL/A',
  'CORRESP',
  'CT ORDER',
  'D',
  'D/A',
  'DEF 14A',
  'DEF 14C',
  'DEFA14A',
  'DEFA14C',
  'DEFC14A',
  'DEFM14A',
  'DEFM14C',
  'DEFR14A',
  'DEFR14C',
  'DEL AM',
  'DFAN14A',
  'DFRN14A',
  'DOS',
  'DOS/A',
  'DOSLTR',
  'DRS',
  'DRS/A',
  'DRSLTR',
  'DSTRBRPT',
  'EFFECT',
  'F-1',
  'F-1/A',
  'F-10',
  'F-10/A',
  'F-1MEF',
  'F-3',
  'F-3/A',
  'F-3ASR',
  'F-3DPOS',
  'F-3MEF',
  'F-4',
  'F-4/A',
  'F-6',
  'F-6 POS',
  'F-6/A',
  'F-6EF',
  'F-N',
  'F-N/A',
  'F-X',
  'F-X/A',
  'FOCUSN',
  'FWP',
  'G-405',
  'IRANNOTICE',
  'MA',
  'MA-A',
  'MA-I',
  'MA-I/A',
  'MA-W',
  'MA/A',
  'MSD/A',
  'N-14',
  'N-14 8C',
  'N-14 8C/A',
  'N-14/A',
  'N-18F1',
  'N-1A',
  'N-1A/A',
  'N-2',
  'N-2 POSASR',
  'N-2/A',
  'N-23C-2',
  'N-23C-2/A',
  'N-23C3A',
  'N-23C3B',
  'N-2ASR',
  'N-2MEF',
  'N-30B-2',
  'N-30D',
  'N-4',
  'N-4/A',
  'N-54A',
  'N-54C',
  'N-6',
  'N-6/A',
  'N-6F',
  'N-8A',
  'N-8A/A',
  'N-8B-4',
  'N-8F',
  'N-8F NTC',
  'N-8F ORDR',
  'N-8F/A',
  'N-CEN',
  'N-CEN/A',
  'N-CR',
  'N-CSR',
  'N-CSR/A',
  'N-CSRS',
  'N-CSRS/A',
  'N-MFP2',
  'N-MFP2/A',
  'N-PX',
  'N-PX/A',
  'N-VPFS',
  'NPORT-P',
  'NPORT-P/A',
  'NRSRO-CE',
  'NRSRO-UPD',
  'NT 10-K',
  'NT 10-K/A',
  'NT 10-Q',
  'NT 10-Q/A',
  'NT 20-F',
  'NT N-CEN',
  'NT NPORT-P',
  'NT-NCEN',
  'NT-NCSR',
  'POS 8C',
  'POS AM',
  'POS AMI',
  'POS EX',
  'POS462C',
  'POSASR',
  'PRE 14A',
  'PRE 14C',
  'PREC14A',
  'PREM14A',
  'PREM14C',
  'PREN14A',
  'PRER14A',
  'PRER14C',
  'PRRN14A',
  'PX14A6G',
  'QRTLYRPT',
  'QUALIF',
  'REVOKED',
  'RW',
  'RW WD',
  'S-1',
  'S-1/A',
  'S-11',
  'S-11/A',
  'S-1MEF',
  'S-3',
  'S-3/A',
  'S-3ASR',
  'S-3D',
  'S-3DPOS',
  'S-3MEF',
  'S-4',
  'S-4/A',
  'S-4MEF',
  'S-6',
  'S-6/A',
  'S-8',
  'S-8 POS',
  'S-B',
  'S-B/A',
  'SC 13D',
  'SC 13D/A',
  'SC 13E3',
  'SC 13E3/A',
  'SC 13G',
  'SC 13G/A',
  'SC 14D9',
  'SC 14D9/A',
  'SC 14F1',
  'SC 14N',
  'SC TO-C',
  'SC TO-I',
  'SC TO-I/A',
  'SC TO-T',
  'SC TO-T/A',
  'SC14D9C',
  'SD',
  'SEC STAFF ACTION',
  'SEC STAFF LETTER',
  'SF-1',
  'SF-1/A',
  'SF-3',
  'SF-3/A',
  'SUPPL',
  'T-3',
  'TA-1',
  'TA-1/A',
  'TA-2',
  'TA-2/A',
  'TA-W',
  'UPLOAD',
  'X-17A-5',
  'X-17A-5/A',
  '10-12B/A',
  '10-KT/A',
  '11-K/A',
  '11-KT',
  '15F-12G',
  '20FR12G/A',
  '25/A',
  '40-202A/A',
  '40-206A',
  '40-206A/A',
  '40-6B/A',
  '40-8B25',
  '6B NTC',
  'ADV-H-T',
  'ANNLRPT',
  'ARS/A',
  'ATS-N',
  'ATS-N-C',
  'AW WD',
  'C-AR-W',
  'C-AR/A',
  'C-TR-W',
  'CERTCBO',
  'CFPORTAL',
  'CFPORTAL-W',
  'DEFN14A',
  'F-10POS',
  'F-3D',
  'F-4 POS',
  'F-4EF',
  'F-7',
  'FOCUSN/A',
  'G-FIN',
  'G-FIN/A',
  'N-14MEF',
  'N-23C3A/A',
  'N-54A/A',
  'N-6F/A',
  'N-8B-2',
  'N-CR/A',
  'N-Q',
  'N-Q/A',
  'NPORT-EX/A',
  'NRSRO-CE/A',
  'NT 11-K',
  'NT N-MFP2',
  'NT NPORT-EX',
  'NT-NCEN/A',
  'NT-NCSR/A',
  'NTFNCEN',
  'NTFNCSR',
  'OIP NTC',
  'OIP ORDR',
  'POS462B',
  'S-4 POS',
  'S-4EF',
  'SC 14F1/A',
  'SC 14N/A',
  'SC14D1F',
  'SC14D1F/A',
  'SD/A',
  'SE',
  'SEC ACTION',
  'T-3/A',
  'T-6',
  '13FCONP',
  '15F-12B/A',
  '15F-15D/A',
  '40-17F2/A',
  '40-202A',
  '40-24B2/A',
  '8-K12G3/A',
  '8-M',
  'ADV-H-C',
  'CERTBATS',
  'G-405N',
  'MSDW',
  'N-18F1/A',
  'N-23C3C',
  'N-30D/A',
  'NPORT-EX',
  'NSAR-B',
  'NSAR-B/A',
  'NSAR-U',
  'PX14A6N',
  'QRTLYRPT/A',
  'REG-NR',
  'S-11MEF',
  'SC13E4F',
  'SC13E4F/A',
  'SC14D9F',
  'STOP ORDER',
  '1-Z-W',
  '40-17F1/A',
  '8-K15D5',
  '9-M',
  'CERTNAS',
  'DSTRBRPT/A',
  'F-10EF',
  'F-7/A',
  'F-8 POS',
  'F-8/A',
  'F-80',
  'F-80/A',
  'F-80POS',
  'N-23C3C/A',
  'N-8B-2/A',
  'NSAR-A',
  'NSAR-A/A',
  'NSAR-BT',
  'NSAR-U/A',
  'NT 20-F/A',
  'NT-NSAR',
  'NT-NSAR/A',
  'SDR/A',
  'TACO',
  '1-E/A',
  '10-QT/A',
  '15F-12G/A',
  '2-E',
  '305B2/A',
  '34-12H',
  '40-33/A',
  '497H2',
  'APP WD/A',
  'CERTARCA',
  'CERTNYS',
  'F-8',
  'G-FINW',
  'N-MFP1/A',
  'NO ACT',
  'NSAR-AT',
  'NT 10-D',
  'NTFNSAR',
  'SP 15D2',
  '1-E AD',
  'F-4MEF',
  'MSD',
  'N-MFP',
  'N-MFP/A',
  'N-MFP1',
  'NT N-MFP',
  'NT N-MFP1',
  'NTN 10K',
  'NTN 10Q',
  'PREC14C',
  'S-BMEF',
  'SC 13E1/A',
  'SDR',
  '1-E',
  '11-KT/A',
  '2-A',
  '2-A/A',
  'DEFC14C',
  'F-7 POS',
  'NSAR-AT/A',
  'NSAR-BT/A',
  'NT 11-K/A',
  'NTN 10D',
  'SC 13E1',
  'WDL-REQ',
  '18-12B',
  'N-54C/A',
  'SC14D9F/A',
  'TTW',
  'TTW/A',
  '2-AF',
  'ADN-MTL',
  'CERTPAC',
  'N-23C3B/A',
  'N-5',
  'N-5/A',
  'S-20/A',
  'SL',
  'ADV-E',
  'F-9',
  'F-9 POS',
  'F-9/A',
  'G-405N/A',
  'N-1',
  'S-20',
  'SP 15D2/A',
  'MSDCO',
  'UNDER',
  'G-405/A',
  'NTN 20F',
  '10KSB',
  '10KSB/A',
  '2-E/A',
  '40-203A',
  '497K3A',
  '497K3B',
  'NT 10-D/A',
  'REGDEX',
  'REGDEX/A',
  '10QSB',
  '10QSB/A',
  '10SB12B',
  '10SB12G',
  '10SB12G/A',
  '12G3-2B',
  '12G32BR',
  '497K1',
  '497K2',
  'CERTAMX',
  'N-14AE',
  'N-14AE/A',
  'SB-2',
  'SB-2/A',
  '10SB12B/A',
  '19B-4',
  '39-304D',
  '40-6C',
  '40-6C/A',
  'CERTBSE',
  'DEF-OC',
  'N-3',
  'NT 15D2',
  'REG-NR/A',
  'S-4EF/A',
  'SB-1',
  'SB-1/A',
  'SB-2MEF',
  '35-CERT',
  '35-CERT/A',
  'ANNLRPT/A',
  'CERTCSE',
  'N-27D-1',
  'POS AMC',
  'U-1/A',
  'U-12-IB',
  'U-57',
  'U-6B-2',
  'U-7D',
  'U5A',
  '40-205E',
  '40-205E/A',
  '40-RPT',
  'F-2',
  'F-2/A',
  'N-1/A',
  'N-23C-1',
  'S-2',
  'S-2/A',
  'S-2MEF',
  'U-1',
  'U-12-IA',
  'U-12-IB/A',
  'U-13-60',
  'U-13-60/A',
  'U-13E-1',
  'U-33-S',
  'U-3A-2',
  'U-3A-2/A',
  'U-3A3-1',
  'U-57/A',
  'U-7D/A',
  'U-9C-3',
  'U-9C-3/A',
  'U5A/A',
  'U5B',
  'U5S',
  'U5S/A',
  '13FCONP/A',
  '39-304D/A',
  '40-8F-A',
  '40-8F-A/A',
  '40-8F-L',
  '40-8F-L/A',
  '40-8F-M',
  '40-8F-M/A',
  '40-8FC',
  'ID-NEWCIK',
  'N-23C-1/A',
  'N-3/A',
  'U-6B-2/A',
  'U5B/A',
  '40-203A/A',
  'ADB',
  'ADVCO',
  'AFDB',
  'AFDB/A',
  'BDCO',
  'BW-2',
  'EBRD',
  'EBRD/A',
  'F-9EF',
  'IADB',
  'IFC',
  '10-K405',
  '10-K405/A',
  '10-KSB',
  '10-KSB/A',
  '10-QSB',
  '10KSB40',
  '10KSB40/A',
  '10KT405',
  '39-310B',
  '40-8F-B',
  '40-8FC/A',
  'ADV/A',
  'ADVW',
  'BW-3',
  'CERTPBS',
  'DEFS14A',
  'DEFS14C',
  'NTN15D2',
  'PRES14A',
  'PRES14C',
  'SC 14D1/A',
  'U-12-IA/A',
  '10-QSB/A',
  '10KT405/A',
  '35-APP',
  '40-8F-B/A',
  '8-K15D5/A',
  'SB-1MEF',
  'SC 13E4',
  'SC 13E4/A',
  'U-33-S/A',
  'UNDER/A',
  '35-APP/A',
  'DEF13E3',
  'DEF13E3/A',
  'NT 15D2/A',
  'PRE13E3',
  'PRE13E3/A',
  'PREA14A',
  'SC 14D1',
  '13F-E',
  '13F-E/A',
  'PREA14C',
  '10-C',
  '10-C/A',
  '24F-1',
  '24F-2EL',
  '24F-2TM',
  '485A24E',
  '485A24F',
  '485B24E',
  '485B24F',
  '485BXTF',
  '8-B12B',
  '8-B12B/A',
  '8-B12G',
  '8-B12G/A',
  '8A12BEF',
  '8A12BT',
  '8A12BT/A',
  'N-1A EL',
  'N-1A EL/A',
  'N-3 EL',
  'N-3 EL/A',
  'N-4 EL',
  'N-4 EL/A',
  'N14AE24',
  'N14AE24/A',
  'N14EL24',
  'N14EL24/A',
  'S-6EL24',
  'S-6EL24/A',
  '24F-2EL/A',
  '486B24E',
  '486A24E',
  'NTN 11K',
  'S-3D/A',
  'S-8/A'
];
